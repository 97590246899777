<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card class="fixed-card">
      <v-card-title ref="cardTitle">
        被试人员管理
        <AppTooltipBtn
          rounded
          btn-class="ml-6"
          color="primary"
          icon="mdi-account-plus"
          label="添加"
          tooltip="创建一个新的被试者"
          @click="showPersonInfoDialog(true)"
        />
        <AppTooltipBtn
          v-if="!isForbidIdentity"
          rounded
          btn-class="ml-4"
          color="primary"
          icon="mdi-file-excel-outline"
          label="导入"
          tooltip="通过 Excel 批量导入被试者"
          @click="isShowPersonImportDialog = true"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-4"
          color="error"
          icon="mdi-account-remove"
          label="删除"
          tooltip="批量删除被试者"
          @click="showPersonDeleteDialog(true)"
        />
        <AppTooltipBtn
          v-if="!isForbidIdentity"
          rounded
          btn-class="ml-4"
          color="success"
          icon="mdi-key-variant"
          label="密码重置"
          tooltip="批量重置被试者的登录密码"
          @click="showBulkResetPasswordDialog()"
        />
        <v-spacer></v-spacer>
        <AppTooltipBtn
          text
          rounded
          btn-class="ml-5 my-2"
          color="green"
          icon="mdi-tune"
          :label="isShowFilterPanel ? '关闭筛选面板' : '打开筛选面板'"
          tooltip="打开或关闭筛选面板"
          @click="toggleFilterPanel"
        />
      </v-card-title>
      <v-slide-y-transition>
        <v-card-text ref="filterPanel" v-if="isShowFilterPanel">
          <div class="d-flex flex-wrap mx-2">
            <v-select
              class="mr-3 mb-3 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              hide-details="auto"
              :label="nodeColumnAlias"
              :items="filterableNodeNames"
              v-model="filterNodeNames"
            ></v-select>
            <v-autocomplete
              class="mr-3 mb-3 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              hide-details="auto"
              label="预警"
              :items="filterableAlerts"
              item-text="alertName"
              item-value="alertGuid"
              v-model="filterAlertGuids"
            >
              <template v-slot:selection="{ attrs, item }">
                <v-chip v-bind="attrs" small class="my-1">
                  {{ item.alertName }}
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon small :color="item.alertColorHex">
                      mdi-record
                    </v-icon>
                    {{ item.alertName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.lbDispName }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-text-field
              v-if="!isForbidIdentity"
              class="mr-3 mb-3 filter-item"
              dense
              single-line
              outlined
              clearable
              hide-details="auto"
              label="识别号"
              v-model="filterPersonIdentity"
            ></v-text-field>
            <template v-for="(personInfo, idx) in filterPersonInfoList">
              <v-text-field
                :key="idx"
                class="mr-3 mb-3 filter-item"
                dense
                single-line
                outlined
                clearable
                hide-details="auto"
                :label="personInfo.text"
                v-model="personInfo.value"
              ></v-text-field>
            </template>
            <AppMenuBtn
              color="primary"
              icon="mdi-filter-plus"
              tooltip="添加筛选"
            >
              <v-list>
                <v-list-item
                  v-for="(fieldText, idx) in filterPersonFieldTextList"
                  :key="idx"
                  @click="addOnePersonFilter(fieldText)"
                >
                  {{ fieldText }}
                </v-list-item>
              </v-list>
            </AppMenuBtn>
          </div>
          <AppTooltipBtn
            class="ml-6"
            color="primary"
            label="筛选被试人员"
            icon="mdi-filter"
            small
            small-icon
            @click="filterPersonList"
          />
          <AppTooltipBtn
            class="ml-6"
            text
            color="primary"
            label="重置"
            icon="mdi-refresh"
            small
            @click="resetPersonFilters"
          />
        </v-card-text>
      </v-slide-y-transition>
      <v-data-table
        class="mx-4"
        :height="getDataTableHeight()"
        :key="personDataTableKey"
        :headers="testPersonHeaders"
        fixed-header
        :items="testPersonList"
        item-key="guid"
        :server-items-length="serverPersonCount"
        :options.sync="serverOptions"
        :page.sync="personListPageNum"
        show-select
        :single-select="false"
        :sort-by="testPersonSortBy"
        :sort-desc="testPersonSortDesc"
        multi-sort
        v-model="selectedPersonList"
        :loading="isDataTableLoading"
        loading-text="正在读取被试者列表，请稍候..."
        no-data-text="未找到任何被试者"
        no-results-text="未找到任何匹配的被试者"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageAllText: '所有',
          itemsPerPageText: '每页被试者数量: ',
          itemsPerPageOptions: [5, 10, 20, 50, 100]
        }"
        @item-selected="personRowToggleSelect"
        @toggle-select-all="testPersonToggleSelectAll"
      >
        <template
          v-slot:[`footer.page-text`]="{
            pageStart,
            pageStop,
            itemsLength
          }"
        >
          {{
            `共 ${itemsLength} 个被试者，  本页显示被试者 ${pageStart}-${pageStop} ${selectedPersonCountText}`
          }}
        </template>
        <template v-slot:[`item.age`]="{ value }">
          {{ buildAgeStringFromObj(value) }}
        </template>
        <template v-slot:[`item.loginIdentity`]="{ value }">
          <v-chip v-if="value && value.guid" small>
            {{ `${value.loginKeyType.typeText}: ${value.loginKey}` }}
          </v-chip>
        </template>
        <template v-slot:[`item.lbAlertList`]="{ value }">
          <div v-for="(lbAlert, idx1) in value" :key="idx1">
            <v-tooltip
              bottom
              v-for="(alert, idx2) in lbAlert.alertList"
              :key="idx2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="!!alert.displayName"
                  :color="`${alert.colorHex}`"
                  v-on="on"
                  v-bind="attrs"
                >
                  mdi-record
                </v-icon>
              </template>
              <span>{{ `${lbAlert.lbDispName} ${alert.displayName}` }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-folder-account"
            tooltip="查看个人档案"
            @click="showPersonProfileDialog(item)"
          />
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-pencil"
            tooltip="修改被试者信息"
            @click="showPersonInfoDialog(false, item)"
          />
          <AppTooltipBtn
            color="error"
            icon="mdi-delete"
            tooltip="删除这个被试者"
            @click="showPersonDeleteDialog(false, item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <AppDialog
      v-model="isShowPersonInfoDialog"
      size="small"
      overflow-height="calc(100vh - 270px)"
      persistent
      :title="isCreatingNewPerson ? '新建被试者' : '被试者信息编辑'"
      text-class="px-10"
      color="success"
      action-text="确认修改"
      :loading="isBtnLoading"
      @confirm="editPersonInfoConfirm"
      @closed="personInfoDialogClosed"
    >
      <PersonInfoEditor
        :is-displaying="isShowPersonInfoDialog"
        :is-forbid-identity="
          isForbidIdentity ||
            !editingPersonInfo.loginIdentity ||
            !editingPersonInfo.loginIdentity.guid
        "
        :node-guids="nodeGuids"
        :region-guid="regionGuid"
        :is-creating-new="isCreatingNewPerson"
        v-model="editingPersonInfo"
        :no-update-fields="testPersonNoUpdateFields"
        @update:no-update-fields="testPersonNoUpdateFields = $event"
        :field-config-list="fieldConfigList"
      />
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteDialog"
      size="small"
      :title="deleteDialogTitle"
      color="red"
      action-text="删除"
      :loading="isBtnLoading"
      @confirm="personDeleteConfirm"
    >
      不可删除已有关联案例的被试者。
    </AppDialog>
    <AppDialog
      v-model="isShowPersonProfileDialog"
      size="large"
      :title="personProfileDialogTitle"
      cancel-text="关闭"
      @closed="personProfileDialogClosed"
    >
      <PersonPsychoProfile
        :person-guid="actionPersonInfo.guid || ''"
        :node-guid="actionPersonInfo.nodeGuid || ''"
        :field-config-list="fieldConfigList"
      />
    </AppDialog>
    <PersonInfoExcelImportDialog
      v-if="!isForbidIdentity"
      v-model="isShowPersonImportDialog"
      :region-guid="regionGuid"
      :node-guids="nodeGuids"
      @closed="personImportDialogClosed"
    />
    <CredentialsPasswordDialog
      v-if="!isForbidIdentity"
      v-model="isShowBulkResetPasswordDialog"
      :person-guid-list="selectedPersonGuidList"
      :is-new-pwd="true"
      @closed="bulkResetPasswordDialogClosed"
    />
    <AppMessageBox title="发生错误" v-model="errorMessage" />
  </div>
</template>

<script>
import PersonInfoEditor from "@/components/PersonInfoEditor";
import PersonPsychoProfile from "@/components/PersonPsychoProfile";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import PersonInfoExcelImportDialog from "@/components/PersonInfoExcelImportDialog";
import CredentialsPasswordDialog from "@/components/CredentialsPasswordDialog";
import { mapGetters } from "vuex";
import {
  fetchTestPersonList,
  fetchTestPersonGuidList,
  fetchTestPersonDetails,
  createNewTestPerson,
  saveByCreatingNewEditedPerson,
  bulkDeletePerson
} from "@/api/person";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { fetchManageNodeFlatSelectList } from "@/api/manageNode";
import { getAlertDispListForFilter } from "@/api/lb";
import {
  personLoginIdentitySortFunc,
  specialConfigField
} from "@/utils/constants/fieldConfig";
import {
  buildLimitOffsetObj,
  buildOrderList
} from "@/utils/backendPagingUtils";
import { simpleDeepCopy } from "@/utils/objectUtils";
import { buildAgeString } from "@/utils/personUtils";
import { fetchVisibleIdentityTypeList } from "@/api/userIdentity";

export default {
  components: {
    PersonInfoEditor,
    PersonPsychoProfile,
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox,
    AppTooltipBtn,
    AppMenuBtn,
    PersonInfoExcelImportDialog,
    CredentialsPasswordDialog
  },

  data() {
    return {
      isLoading: false,
      isBtnLoading: false,
      isDataTableLoading: false,
      personDataTableKey: 0,
      errorMessage: "",
      testPersonNoUpdateFields: [],
      isInitPersonList: false,
      serverPersonCount: 0,
      serverOptions: {},
      personListPageNum: 1,
      testPersonList: [],
      selectedPersonList: [],
      selectedPersonGuidList: [],
      isPersonSelectAll: false,
      testPersonSortBy: [],
      testPersonSortDesc: [],
      testPersonHeaders: [],
      fieldConfigList: [],
      // person filters
      isShowFilterPanel: true,
      filterPersonInfoList: [],
      filterPersonIdentity: "",
      filterableNodeNames: [],
      filterableAlerts: [],
      filterNodeNames: [],
      filterAlertGuids: [],
      filterPersonFieldTextList: [],
      // dialog
      isShowPersonInfoDialog: false,
      isShowDeleteDialog: false,
      isShowPersonImportDialog: false,
      isShowPersonProfileDialog: false,
      isShowBulkResetPasswordDialog: false,
      // action person
      isForbidIdentity: false,
      isCreatingNewPerson: false,
      isBulkDeletePerson: false,
      actionPersonInfo: {},
      editingPersonInfo: {},
      actionPersonIndex: -1
    };
  },

  watch: {
    serverOptions: {
      handler() {
        // 初始化的时候不自动刷新
        if (!this.isInitPersonList) {
          this.refreshTestPersonList();
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids"
    }),
    selectedPersonCountText() {
      if (this.selectedPersonGuidList && this.selectedPersonGuidList.length) {
        return `，选中了${this.selectedPersonGuidList.length}个被试者`;
      }
      return "";
    },
    personProfileDialogTitle() {
      return `个人心理档案 - ${this.actionPersonInfo.name}`;
    },
    deleteDialogTitle() {
      return this.isBulkDeletePerson
        ? `确定要删除选中的 ${this.selectedPersonGuidList.length} 个被试者吗？`
        : `确定要删除被试者 ${this.actionPersonInfo.name} 吗？`;
    },
    nodeColumnAlias() {
      let nodeFieldConfig = this.fieldConfigList.filter(
        field => field.fieldName === specialConfigField.node.fieldName
      );
      if (nodeFieldConfig && nodeFieldConfig.length) {
        return nodeFieldConfig[0].fieldAlias;
      }
      return "";
    }
  },

  methods: {
    buildAgeStringFromObj(ageObj) {
      return buildAgeString(ageObj);
    },
    resetServerOptions() {
      this.serverOptions.sortBy = ["createdDate"];
      this.serverOptions.sortDesc = [true];
    },
    // ============================ Fetch Data =================================
    buildPersonListFilterObj() {
      let personInfoList = simpleDeepCopy(this.filterPersonInfoList || []);
      // 添加用户识别号的筛选
      if (this.filterPersonIdentity) {
        personInfoList.push({
          field: "loginIdentity",
          text: "",
          value: {
            loginKey: this.filterPersonIdentity
          }
        });
      }
      return {
        nodeNames: this.filterNodeNames
          ? this.filterNodeNames.map(dName => (dName === "无" ? null : dName))
          : [],
        alertDispGuids: this.filterAlertGuids
          ? this.filterAlertGuids.map(aGuid =>
            aGuid === "无预警" ? null : aGuid
          )
          : [],
        personInfoList
      };
    },
    async refreshTestPersonList() {
      try {
        this.isDataTableLoading = true;
        let { sortBy, sortDesc, page, itemsPerPage } = this.serverOptions;
        let response = await fetchTestPersonList(
          this.regionGuid,
          this.nodeGuids,
          this.buildPersonListFilterObj(),
          buildLimitOffsetObj(page, itemsPerPage),
          buildOrderList(sortBy, sortDesc)
        );
        this.serverPersonCount = response.count;
        let rawPersonList = response.personList;
        this.testPersonList = rawPersonList.map(p => {
          return {
            ...p.testPerson,
            lbAlertList: p.lbAlertList
          };
        });
        if (this.isPersonSelectAll) {
          this.selectedPersonList = this.testPersonList;
        }
      } catch (err) {
        this.errorMessage = err.message;
      }
      this.isDataTableLoading = false;
    },
    async fetchUserFieldConfigList() {
      try {
        this.fieldConfigList = await getUserFieldConfig(this.nodeGuids);
        this.refreshPersonFilterTextList();
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async fetchVisibleIdentityTypes() {
      try {
        this.isLoading = true;
        let visibleIdTypes = await fetchVisibleIdentityTypeList(
          this.regionGuid,
          null,
          this.nodeGuids
        );
        this.isForbidIdentity = !visibleIdTypes || !visibleIdTypes.length;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    resetTestPersonHeaders() {
      this.testPersonHeaders = [];
      for (let fieldConfig of this.fieldConfigList) {
        // 对 nodeGuid column 来说，直接使用 nodeName 是最高效的，可以不用再次获取 nodeSelectList
        if (fieldConfig.isVisibleInTable) {
          if (fieldConfig.fieldName === specialConfigField.node.fieldName) {
            this.testPersonHeaders.push({
              text: fieldConfig.fieldAlias,
              value: specialConfigField.node.dispFieldName
            });
          } else {
            this.testPersonHeaders.push({
              text: fieldConfig.fieldAlias,
              value: fieldConfig.fieldName
            });
          }
        }
      }
      if (!this.isForbidIdentity) {
        this.testPersonHeaders.push({
          text: "识别号",
          value: "loginIdentity",
          sort: personLoginIdentitySortFunc
        });
      }
      this.testPersonHeaders.push(
        { text: "更新时间", value: "createdDate" },
        { text: "预警", value: "lbAlertList" },
        {
          text: "操作",
          value: "actions",
          sortable: false
        }
      );
    },
    // ============================ DataTable fit =================================
    getCardTitleHeight() {
      if (this.$refs.cardTitle) {
        return this.$refs.cardTitle.offsetHeight;
      }
      return 0;
    },
    getFilterPanelHeight() {
      if (this.$refs.filterPanel) {
        return this.$refs.filterPanel.offsetHeight;
      }
      return 0;
    },
    getDataTableHeight() {
      let overflowHeight =
        200 + this.getCardTitleHeight() + this.getFilterPanelHeight();
      return `calc(100vh - ${overflowHeight}px)`;
    },
    // ============================ shared functions ============================
    assignPersonInfo(info) {
      this.actionPersonIndex = info ? this.testPersonList.indexOf(info) : -1;
      this.actionPersonInfo = simpleDeepCopy(info || {});
      // Object.assign(this.actionPersonInfo, simpleDeepCopy(info || {}));
    },
    resetPersonInfo() {
      this.assignPersonInfo(null);
      // 初始化需要注册绑定的字段
      this.editingPersonInfo = {
        loginIdentity: {
          loginKey: "",
          loginKeyType: {
            typeText: "",
            typeValue: ""
          }
        },
        hasPassword: false,
        password: "",
        name: "",
        gender: "",
        dateOfBirth: "",
        eduLevel: "",
        marriage: "",
        job: "",
        nodeGuid: "",
        nodeName: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: ""
      };
    },
    // ============================ person backend filters ============================
    toggleFilterPanel() {
      this.isShowFilterPanel = !this.isShowFilterPanel;
      // 刷新 dataTable 的显示高度
      this.personDataTableKey++;
    },
    refreshPersonFilterTextList() {
      if (this.fieldConfigList) {
        // 添加 PersonInfo 筛选的 MenuBtn
        this.filterPersonFieldTextList = this.fieldConfigList
          .filter(
            field =>
              field.fieldName !== specialConfigField.node.fieldName &&
              field.isVisibleInTable
          )
          .map(field => field.fieldAlias);
      }
    },
    addOnePersonFilter(fieldText) {
      let matchedFieldConfig = this.fieldConfigList.find(
        fc => fc.fieldAlias === fieldText
      );
      if (matchedFieldConfig && matchedFieldConfig.fieldName) {
        this.filterPersonInfoList.push({
          text: fieldText,
          field: matchedFieldConfig.fieldName,
          value: ""
        });
        // 在 MenuBtn 中删除已添加的 field
        let fieldTextIdx = this.filterPersonFieldTextList.findIndex(
          pText => pText === fieldText
        );
        this.filterPersonFieldTextList.splice(fieldTextIdx, 1);
      }
    },
    resetFilterSelectList() {
      this.fetchFilterableNodeNames();
      this.fetchFilterableAlerts();
    },
    async fetchFilterableNodeNames() {
      try {
        this.isLoading = true;
        let nodeFlatList = await fetchManageNodeFlatSelectList(
          this.regionGuid,
          this.nodeGuids
        );
        this.filterableNodeNames = nodeFlatList.map(node => node.text);
        this.filterableNodeNames.push("无");
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async fetchFilterableAlerts() {
      try {
        this.isLoading = true;
        this.filterableAlerts = await getAlertDispListForFilter(
          this.regionGuid,
          this.nodeGuids,
          null
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    filterPersonList() {
      // 筛选前清空选择，并重置到第一页
      this.selectedPersonList = [];
      this.selectedPersonGuidList = [];
      this.isPersonSelectAll = false;
      this.personListPageNum = 1;
      this.resetTestPersonHeaders();
      this.refreshTestPersonList();
    },
    resetPersonFilters() {
      this.filterNodeNames = [];
      this.filterAlertGuids = [];
      this.filterPersonIdentity = "";
      this.resetPersonFilterFieldSelected();
      this.filterPersonList();
    },
    resetPersonFilterFieldSelected() {
      this.filterPersonInfoList = [];
      this.refreshPersonFilterTextList();
    },
    // ============================ person toggle select all ============================
    personRowToggleSelect({ item, value }) {
      // 选中单行时，取消全选
      this.isPersonSelectAll = false;
      let curSelGuidList = this.selectedPersonList.map(p => p.guid);
      if (value) {
        curSelGuidList.push(item.guid);
      } else {
        let deleteIndex = curSelGuidList.findIndex(
          cGuid => cGuid === item.guid
        );
        curSelGuidList.splice(deleteIndex, 1);
      }
      this.selectedPersonGuidList = curSelGuidList;
    },
    testPersonToggleSelectAll({ value }) {
      if (value) {
        this.isPersonSelectAll = true;
        this.setSelectAllPersonGuidList();
      } else {
        this.isPersonSelectAll = false;
        this.selectedPersonList = [];
        this.selectedPersonGuidList = [];
      }
    },
    async setSelectAllPersonGuidList() {
      try {
        this.isLoading = true;
        this.selectedPersonGuidList = await fetchTestPersonGuidList(
          this.regionGuid,
          this.nodeGuids,
          this.buildPersonListFilterObj()
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ============================ person profiles ============================
    showPersonProfileDialog(clickedItem) {
      this.assignPersonInfo(clickedItem);
      this.isShowPersonProfileDialog = true;
    },
    personProfileDialogClosed() {
      this.resetPersonInfo();
    },
    // ============================ edit person ============================
    async showPersonInfoDialog(isNewPerson, clickedItem = null) {
      this.isCreatingNewPerson = isNewPerson;
      if (isNewPerson) {
        this.resetPersonInfo();
      } else {
        this.assignPersonInfo(clickedItem);
        let backendPerson = await this.getTestPersonDetails(
          this.actionPersonInfo.guid
        );
        Object.assign(this.editingPersonInfo, backendPerson);
      }
      this.isShowPersonInfoDialog = true;
    },
    personInfoDialogClosed() {
      this.resetPersonInfo();
    },
    async getTestPersonDetails(personGuid) {
      let personDetails = {};
      try {
        this.isLoading = true;
        personDetails = await fetchTestPersonDetails(personGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
      return personDetails;
    },
    async editPersonInfoConfirm() {
      // 必须要添加 identity
      if (
        !this.isForbidIdentity &&
        (!this.editingPersonInfo.loginIdentity ||
          !this.editingPersonInfo.loginIdentity.loginKeyType ||
          !this.editingPersonInfo.loginIdentity.loginKeyType.typeValue)
      ) {
        this.errorMessage = "未设置答题账号，请点击上方“+”添加答题账号";
        return;
      }
      if (!this.editingPersonInfo.nodeGuid) {
        this.errorMessage = "未设置测评单位";
        return;
      }
      try {
        this.isBtnLoading = true;
        if (this.isCreatingNewPerson) {
          let newPersonGuid = await createNewTestPerson(
            this.regionGuid,
            {
              ...this.editingPersonInfo
            },
            this.editingPersonInfo.password
          );
          let newPersonInfo = await this.getTestPersonDetails(newPersonGuid);
          this.testPersonList.unshift(newPersonInfo);
        } else {
          this.editingPersonInfo.guid = await saveByCreatingNewEditedPerson(
            this.regionGuid,
            {
              ...this.editingPersonInfo
            },
            this.testPersonNoUpdateFields
          );
          // 判断是否设置了 password
          if (
            !this.editingPersonInfo.hasPassword &&
            this.editingPersonInfo.password
          ) {
            this.editingPersonInfo.hasPassword = true;
          }
          Object.assign(
            this.testPersonList[this.actionPersonIndex],
            simpleDeepCopy(this.editingPersonInfo)
          );
        }
        this.isShowPersonInfoDialog = false;
      } catch (err) {
        this.errorMessage = err.message;
      }
      this.isBtnLoading = false;
    },
    // ============================ delete person ============================
    showPersonDeleteDialog(isBulkDelete, clickedItem = null) {
      this.isBulkDeletePerson = isBulkDelete;
      // 没选中的话，批量删除不弹出对话框
      // 但是单个删除的情况，永远会弹出对话框
      if (isBulkDelete && this.selectedPersonGuidList.length) {
        this.isShowDeleteDialog = true;
      } else if (!isBulkDelete && clickedItem) {
        this.assignPersonInfo(clickedItem);
        this.isShowDeleteDialog = true;
      }
    },
    async personDeleteConfirm() {
      try {
        this.isBtnLoading = true;
        if (this.isBulkDeletePerson && this.selectedPersonGuidList.length) {
          await bulkDeletePerson(this.selectedPersonGuidList);
          // 更新显示的列表
          this.testPersonList = this.testPersonList.filter(
            c => !this.selectedPersonGuidList.includes(c.guid)
          );
        } else if (!this.isBulkDeletePerson) {
          await bulkDeletePerson([this.actionPersonInfo.guid]);
          // 更新显示的列表
          this.testPersonList.splice(this.actionPersonIndex, 1);
        }
      } catch (err) {
        this.errorMessage = err.message;
      }
      this.isShowDeleteDialog = false;
      this.isBtnLoading = false;
    },
    // ============================ bulk reset person password ============================
    showBulkResetPasswordDialog() {
      if (this.selectedPersonGuidList && this.selectedPersonGuidList.length) {
        this.isShowBulkResetPasswordDialog = true;
      }
    },
    bulkResetPasswordDialogClosed() {
      // 修改密码后需要重置 personList
      this.filterPersonList();
    },
    // ============================ import person list ============================
    personImportDialogClosed() {
      this.filterPersonList();
    }
  },

  async created() {
    this.isInitPersonList = true;
    await this.fetchUserFieldConfigList();
    await this.fetchVisibleIdentityTypes();
    this.resetTestPersonHeaders();
    this.resetPersonInfo();
    // 初始化 sort
    this.resetServerOptions();
    // 初始化 filter
    this.resetFilterSelectList();
    await this.resetPersonFilters();
    this.isInitPersonList = false;
  }
};
</script>

<style lang="scss" scoped>
.fixed-card {
  height: calc(100vh - 125px);
}

.filter-item {
  max-width: 300px;
  min-width: 150px;
}
</style>
